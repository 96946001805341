const Live = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.58333 3C1.70888 3 1 3.70888 1 4.58333V15.7816C1 16.656 1.70888 17.3649 2.58333 17.3649H9.69597H10.0743H13.7816C14.656 17.3649 15.3649 16.656 15.3649 15.7816V13.9019L17.5672 15.0948C18.6222 15.6663 19.9046 14.9024 19.9046 13.7026V6.66298C19.9046 5.46318 18.6222 4.69932 17.5672 5.27077L15.3649 6.46368V4.58333C15.3649 3.70888 14.656 3 13.7816 3H2.58333ZM15.3649 8.1696V12.196L18.2816 13.7759C18.3003 13.786 18.3133 13.7874 18.323 13.7871C18.3348 13.7866 18.3494 13.7829 18.364 13.7742C18.3785 13.7655 18.3888 13.7545 18.3948 13.7443C18.3997 13.736 18.4046 13.7239 18.4046 13.7026V6.66298C18.4046 6.64173 18.3997 6.62963 18.3948 6.62129C18.3888 6.6111 18.3785 6.60007 18.364 6.59138C18.3494 6.58269 18.3348 6.57897 18.323 6.57852C18.3133 6.57816 18.3003 6.57959 18.2816 6.58971L15.3649 8.1696ZM10.0743 15.8649H9.69597H2.58333C2.53731 15.8649 2.5 15.8276 2.5 15.7816V4.58333C2.5 4.53731 2.53731 4.5 2.58333 4.5H13.7816C13.8276 4.5 13.8649 4.53731 13.8649 4.58333V15.7816C13.8649 15.8276 13.8276 15.8649 13.7816 15.8649H10.0743ZM5.34456 9.05428C5.75878 9.05428 6.09456 9.39007 6.09456 9.80428V13.5881C6.09456 14.0023 5.75878 14.3381 5.34456 14.3381C4.93035 14.3381 4.59456 14.0023 4.59456 13.5881L4.59456 9.80428C4.59456 9.39007 4.93035 9.05428 5.34456 9.05428Z"
        fill="#1D1D1F"
      />
    </svg>
  )
}

export default Live
